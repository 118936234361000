<template>
<div class="navBar-cust" id="terms" >
  <div class="outline">
    <div class="wrapper">
      <div class="box">
        <h1 class="outline__ttl">利用規約</h1>
        <div class="outline__section">
          <p class="outline__text">この利用規約（以下「本規約」といいます）は、有限会社フウズラボ（以下「当社」といいます）が運営するインターネット卒業アルバム作成支援サービス「SuperAlbum」（以下「本サービス」といいます）につき、利用条件を定めるものです。本規約に同意されたお客様（以下「ユーザ」といいます）に限り、本サービスのご利用ができるものといたします。</p>
          <ol class="outline__listWrap main">
            <li>（本サービスの目的等）
                <ol class="sub">
                  <li>本サービスは、ユーザが撮影して、本サービス用のサーバにアップロードした画像（以下「アップロード画像」といいます）から顔検索によりマッチング処理をし、各種条件設定に基づき、卒業アルバム等で使用する画像の最適な組み合わせを自動的に計算・提案するサービスです。</li>
                  <li>本サービスの利用にあたり、ユーザはサブユーザ権限（第7条）、閲覧権限（第8条）を設定し、弊社が定める利用権限に基づき、ユーザが必要と認める第三者にそれらの権限を付与することができます。それぞれの権限を付与する際に、ユーザは最大限の注意を払い、その管理をするものとします。</li>
                  <li>本サービスはアップロード画像のバックアップを目的としたものではありません。アップロード画像は、ユーザとの契約に基づく本サービス提供期間（第5条第3項参照）終了時に全て削除されます。</li>
                  <li>ユーザは、自己の責任と費用負担において、本サービスを利用するにあたって必要となる端末機器や電気通信回線を用意するものとします。</li>
                  <li>ユーザは当社のウェブサイト（以下「本ウェブサイト」といいます）上でサブユーザ（第7条）との区別を明確にするため「メインユーザ」または「Mainユーザ」と表記されることがあります。また、サブユーザを「Subユーザ」、閲覧権限を付与された第三者を「閲覧ユーザ」と表記される場合があります。</li>
                </ol>
            </li>
            <li>（適用）
              <p>本規約は、ユーザと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</p>
            </li>
            <li>（利用登録）
                <ol class="sub">
                  <li>本サービスにおいては、登録を希望するお客様が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを承認し、利用登録の申請者が申請したメールアドレスを以てユーザIDを登録することによって、利用登録が完了するものとします。</li>
                  <li>当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
                    <ul>
                      <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
                      <li>本規約に違反したことがある者からの申請である場合</li>
                      <li>ユーザ自身又はユーザの役員若しくはこれらに準ずる者が反社会的勢力に該当する場合</li>
                      <li>その他、当社が利用登録を相当でないと判断した場合</li>
                    </ul>
                  </li>
                </ol>
            </li>
            <li>（ユーザIDおよびパスワードの管理）
                <ol class="sub">
                  <li>ユーザは、自己の責任において、本サービスのユーザIDおよびパスワードを適切に管理するものとします。</li>
                  <li>ユーザは、いかなる場合にも、ユーザIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザIDとパスワードの組み合わせが当該ユーザの登録情報と一致してログインされた場合には、そのユーザIDを登録しているユーザ自身による利用とみなします。</li>
                  <li>ユーザID及びパスワードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。</li>
                </ol>
            </li>
            <li>（利用料金、支払方法及び本サービス提供期間）
                <ol class="sub">
                  <li>ユーザは、本サービスの有料部分の対価として、当社が別途定め、本ウェブサイトに表示する利用料金を、当社が指定する方法により支払うものとします。</li>
                  <li>ユーザが利用料金の支払を遅滞した場合には、ユーザは年14.6%の割合による遅延損害金を支払うものとします。</li>
                  <li>本サービスの提供期間は、当社が別途定め、本ウェブサイトに、表示する期間とします。</li>
                </ol>
            </li>
            <li>（利用条件）
                <ol class="sub">
                  <li>当社が本件サービスを提供するにあたって、次の事項を利用条件とします。なお、ユーザが利用条件に違反し、当社に損害を生じさせた場合には、ユーザは、その損害を賠償する責任を負うものします。</li>
                  <li>ユーザは、アップロード画像について著作者人格権等の権利を行使しないものとします。</li>
                  <li>第三者がアップロード画像の著作権を有している場合には、ユーザは、当該第三者から、当社がアップロード画像について本件サービスを提供することに許諾を受けているものとします。</li>
                </ol>
            </li>
            <li>（サブユーザ権限）
                <ol class="sub">
                  <li>ユーザは、自己の責任において、サブユーザの権限を有するサブユーザ（以下「サブユーザといいます）としてユーザが必要と認める第三者をサブユーザとして登録することができます。なお、サブユーザとして登録できる人数は、別途当社が定める人数とします。</li>
                  <li>ユーザ及びサブユーザは、ユーザ及びサブユーザの責任において、サブユーザIDおよびパスワードを適切に管理するものとします。</li>
                  <li>ユーザは、サブユーザの行為について、一切の責任を負うものとし、サブユーザの行為はユーザの行為とみなして、本規約の定めが適用されます。</li>
                </ol>
            </li>
            <li>（閲覧権限）
                <ol class="sub">
                  <li>ユーザは、自己の責任において、一時的に本ウェブサイトを閲覧できる閲覧用のIDとパスワードの発行を行い、ユーザが閲覧の必要を認める第三者（以下「閲覧者といいます」）に配布することができます。</li>
                  <li>閲覧用IDおよびパスワードの発行の回数と有効期間は、別途当社が定める回数及び、有効期間とします。</li>
                  <li>ユーザ及び閲覧者は、ユーザ及び閲覧者の責任において、閲覧用IDおよびパスワードを適切に管理するものとします。</li>
                  <li>ユーザは、閲覧者の行為について、一切の責任を負うものとし、閲覧者の行為はユーザの行為とみなして、本規約の定めが適用されます。</li>
                </ol>
            </li>
            <li>（禁止事項）
              <p>ユーザは、本サービスの利用にあたり、以下の行為をしてはなりません。</p>
                <ul>
                  <li>本規約に違反する行為</li>
                  <li>法令または公序良俗に違反する行為</li>
                  <li>犯罪行為に関連する行為</li>
                  <li>本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為</li>
                  <li>当社、ほかのユーザ、またはその他第三者のサーバまたはネットワークの機能を破壊したり、妨害したりする行為</li>
                  <li>本サービスによって得られた情報を商業的に利用する行為</li>
                  <li>本サービスの運営を妨害するおそれのある行為</li>
                  <li>不正アクセスをし、またはこれを試みる行為</li>
                  <li>他のユーザに関する個人情報等を収集または蓄積する行為</li>
                  <li>不正な目的を持って本サービスを利用する行為</li>
                  <li>本サービスの他のユーザまたはその他の第三者に不利益、損害、不快感を与える行為</li>
                  <li>他のユーザに成りすます行為</li>
                  <li>当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</li>
                  <li>面識のない第三者との出会いを目的とした行為</li>
                  <li>本サービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</li>
                  <li>その他、前各号に準ずる行為</li>
                  <li>その他、当社が不適切と判断する行為</li>
                </ul>
            </li>
            <li>（本サービスの変更）
                <ol class="sub">
                  <li>当社は、その裁量により、ユーザに対する事前の通知なく、いつでも本サービスの機能の追加、品質維持及び品質向上を目的として、本サービスの全部又は一部を変更できます。</li>
                  <li>当社は、本サービスの変更により、変更前と同等の機能及びサービス内容が維持されることを保証しません。</li>
                </ol>
            </li>
            <li>（本サービスの提供の停止等）
              <p>当社は、以下のいずれかの事由があると判断した場合、ユーザに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</p>
              <ul>
                <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                <li>地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合</li>
                <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                <li>その他、当社が当社の責に帰すべからざる事由に基づき本サービスの提供が困難と判断した場合</li>
              </ul>
              <p>当社は、上記事由に基づく本サービスの提供の停止または中断により、ユーザまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。</p>
            </li>
            <li>（利用制限および登録抹消）
              <p>当社は、ユーザが以下のいずれかに該当する場合には、事前の通知なく、ユーザに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザとしての登録を抹消することができるものとします。</p>
              <ul>
                <li>本規約のいずれかの条項に違反した場合</li>
                <li>第3条に該当する事由がある場合</li>
                <li>登録事項に虚偽の事実があることが判明した場合</li>
                <li>料金等の支払債務の不履行があった場合</li>
                <li>当社からの連絡に対し、一定期間返答がない場合</li>
                <li>本サービスについて、最終の利用から一定期間利用がない場合</li>
                <li>その他、当社が本サービスの利用を適当でないと判断した場合</li>
              </ul>
              <p>当社は、本条に基づき当社が行った行為によりユーザに生じた損害について、一切の責任を負いません。</p>
            </li>
            <li>（退会）
                <ol class="sub">
                  <li>ユーザは、当社の定める退会手続により、本サービスから退会できるものとします。</li>
                  <li>ユーザが前項の退会手続を行った場合であっても、当社は退会完了月までに受領した利用料金を返還する義務を負わず、また、ユーザは退会完了月満了までに発生した利用料金等の支払を免れることはできません。</li>
                </ol>
            </li>
            <li>（保証の否認および免責事項）
                <ol class="sub">
                  <li>当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</li>
                  <li>当社は、本サービスに起因してユーザに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。</li>
                  <li>本サービスに関するユーザと当社との間の契約（本規約を含みます）が消費者契約法に定める消費者契約となる場合、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザに生じた損害のうち逸失利益その他の特別な事情から生じた損害（当社またはユーザが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザに生じた損害の賠償は、ユーザから当該損害が発生した月に受領した利用料金の額を上限とします。</li>
                  <li>当社は、本サービスに関して、ユーザと他のユーザまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。</li>
                </ol>
            </li>
            <li>（サービス内容の変更等）
              <p>当社は、ユーザへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザはこれを承諾するものとします。</p>
            </li>
            <li>（利用規約の変更）
                <ol class="sub">
                  <li>当社は以下の場合には、ユーザの個別の同意を要せず、本規約を変更することができるものとします。</li>
                  <li>本規約の変更がユーザの一般の利益に適合するとき。</li>
                  <li>本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。</li>
                  <li>当社はユーザに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を、本ウェブサイトを利用する方法により周知します。</li>
                </ol>
            </li>
            <li>（個人情報の取扱い）
              <p>当社は、本サービスの利用によって取得する個人情報については、当社「プライバシーポリシー（SuperAlbum）」に従い適切に取り扱うものとします。</p>
            </li>
            <li>（通知または連絡）
              <p>ユーザと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、ユーザから、当社が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザへ到達したものとみなします。</p>
            </li>
            <li>（権利義務の譲渡の禁止）
              <p>ユーザは、当社の書面による事前の承諾なく、本規約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。</p>
            </li>
            <li>（準拠法・裁判管轄）
              <p>本規約の解釈にあたっては、日本法を準拠法とします。<br />本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。</p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
</script>

<style lang='scss' scoped>

#terms {
	ol.main > li {
		list-style-type: none;
		counter-increment: cnt_m;
    margin-left: 1em;
	}
	 
  ol.main > li::before {
    content: "第" counter(cnt_m) "条";
    display:inline-block;
    margin-left:-3em; /* サイトに合せて調整 */
    width: 4em; /* サイトに合せて調整 */
  }
	ol.sub > li {
		list-style-type: none;
		counter-increment: cnt_s;
    margin-left: -1em;
	}
	 
  ol.sub > li::before {
    content: "(" counter(cnt_s) ")";
    display:inline-block;
    margin-left:-2em; /* サイトに合せて調整 */
    width: 2em; /* サイトに合せて調整 */
  }
}

</style>
