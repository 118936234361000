<template>
<div class="navBar-cust">
  <div class="outline">
    <div class="wrapper">
      <div class="box">
        <h1 class="outline__ttl">ご利用方法</h1>
        <div class="outline__section">
          <section class="outline__sectionitem">
            <h2 class="outline__subttl">個人情報の取り扱いにつきまして</h2>
            <p class="outline__text">有限会社フウズラボ(以下、「弊社」といいます)は個人情報を保護することは弊社の事業活動の基本であるとともに、弊社の社会的責務であると考えております。<br>弊社では、次のとおり個人情報保護方針を定め、これを実行し維持することに努めます。</p>
            <ul class="outline__list">
              <li>弊社は、適切な個人情報の収集、利用及び提供を行なうための措置を講じます。</li>
              <li>弊社は、適切な個人情報保護に関する法令およびその他の規範を遵守します。</li>
              <li>弊社は、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、漏洩のリスクに対しては、合理的な安全対策を講じ、事業の実情に合致した経営資源を注入し、個人情報セキュリティ体制を継続的に向上させることで、予防、是正に努めます。</li>
              <li>弊社は、個人情報に関する本人からの自己個人情報の開示、訂正もしくは削除、または利用もしくは提供の拒否をもとめられたときや苦情及び相談の申し出を受けたときは個人情報に関する本人の権利を尊重し、誠意を持って対応いたします。</li>
              <li>弊社は、情報主体のニーズ、IT技術の動向等を含めた弊社をとりまく環境の変化を踏まえ、個人情報保護に関する管理の体制と仕組みを見直しその改善に努めます</li>
            </ul>
          </section>
          <section class="outline__sectionitem">
            <h2 class="outline__subttl">個人情報の取り扱いにつきまして</h2>
            <p class="outline__text">有限会社フウズラボ(以下、「弊社」といいます)は個人情報を保護することは弊社の事業活動の基本であるとともに、弊社の社会的責務であると考えております。<br>弊社では、次のとおり個人情報保護方針を定め、これを実行し維持することに努めます。</p>
            <ol class="outline__list">
              <li>弊社は、適切な個人情報の収集、利用及び提供を行なうための措置を講じます。</li>
              <li>弊社は、適切な個人情報保護に関する法令およびその他の規範を遵守します。</li>
              <li>弊社は、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、漏洩のリスクに対しては、合理的な安全対策を講じ、事業の実情に合致した経営資源を注入し、個人情報セキュリティ体制を継続的に向上させることで、予防、是正に努めます。</li>
              <li>弊社は、個人情報に関する本人からの自己個人情報の開示、訂正もしくは削除、または利用もしくは提供の拒否をもとめられたときや苦情及び相談の申し出を受けたときは個人情報に関する本人の権利を尊重し、誠意を持って対応いたします。</li>
              <li>弊社は、情報主体のニーズ、IT技術の動向等を含めた弊社をとりまく環境の変化を踏まえ、個人情報保護に関する管理の体制と仕組みを見直しその改善に努めます</li>
            </ol>
          </section>
        </div>
        <footer>
          <p class="outline__signature">運営会社　有限会社フウズラボ<br>代表者　牧内節男</p>
        </footer>
      </div>
    </div>

  </div>
</div>
</template>

<script setup>
</script>

<style lang='scss' scoped>
</style>
