import { createApp } from 'vue'

import Cookies from 'js-cookie'

import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/ja'

import '@/assets/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import plugins from './plugins' // plugins
import { download } from '@/utils/request'

import 'virtual:svg-icons-register'
import SvgIcon from '@/components/SvgIcon'
import elementIcons from '@/components/SvgIcon/svgicon'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
library.add(faShoppingCart)

import './permission' // permission control

import { parseTime, resetForm, addDateRange, handleTree, selectDictLabel, selectDictLabels,getYearList,getMonthList,getDayList } from '@/utils/common'

// ページャー
import Pagination from '@/components/Pagination'
// ファイルアップロード
import FileUpload from "@/components/FileUpload"
// 画像アップロード
import ImageUpload from "@/components/ImageUpload"
// 画像プレビュー
import ImagePreview from "@/components/ImagePreview"
// ツリー選択
import TreeSelect from '@/components/TreeSelect'
// ディクショナリー
import DictTag from '@/components/DictTag'

import { CMultiSelect } from '@coreui/vue-pro'


const app = createApp(App)

app.config.globalProperties.download = download
app.config.globalProperties.parseTime = parseTime
app.config.globalProperties.resetForm = resetForm
app.config.globalProperties.handleTree = handleTree
app.config.globalProperties.addDateRange = addDateRange
app.config.globalProperties.selectDictLabel = selectDictLabel
app.config.globalProperties.selectDictLabels = selectDictLabels
app.config.globalProperties.getYearList = getYearList
app.config.globalProperties.getMonthList = getMonthList
app.config.globalProperties.getDayList = getDayList

app.component('DictTag', DictTag)
app.component('Pagination', Pagination)
app.component('TreeSelect', TreeSelect)
app.component('FileUpload', FileUpload)
app.component('ImageUpload', ImageUpload)
app.component('ImagePreview', ImagePreview)

app.use(router)
app.use(store)
app.use(plugins)
app.use(elementIcons)
app.component('CMultiSelect', CMultiSelect)
app.component('svg-icon', SvgIcon)
app.component('font-awesome-icon', FontAwesomeIcon)

import Chat from "vue3-beautiful-chat"
app.use(Chat)

import '@/assets/styles/general.scss'
app.use(ElementPlus, {
  locale: locale,
  size: 'default'
})

// import { registerSW } from 'virtual:pwa-register';

// registerSW({
//   immediate: true,
//   onNeedRefresh() {
//     console.log('New content is available, reloading...');
//     const userConfirmed = confirm('アプリが更新されました。再読み込みしますか？');
//     if (userConfirmed) {
//       updateSW(true); // 新しいバージョンを適用
//     }
//   },
//   onOfflineReady() {
//     console.log('App is ready for offline use.');
//   },
// });

app.mount('#app')
