<template>
<div class="navBar-cust">
  <div class="outline">
    <div class="wrapper">
      <div class="box">
        <h1 class="outline__ttl">プライバシー・ポリシー（SuperAlbum）</h1>
        <div class="outline__section">
          <section class="outline__sectionitem">
            <!-- <h2 class="outline__subttl">個人情報の取り扱いにつきまして</h2> -->
            <p class="outline__text">有限会社フウズラボ(以下、「弊社」といいます)は、個人情報を保護することが弊社の事業活動の基本であるとともに、弊社の社会的責務であると考えております。<br />
              弊社は、個人情報保護について、関連規程の制定及び管理体制の確立を図るとともに、以下のとおりプライバシー・ポリシーを定め、弊社役員及び従業員に周知徹底し、本ポリシーに従って、弊社が提供するSuperAlbumサービス（以下「本サービス」という。）を利用するお客様の個人情報を適切に利用、管理及び保護等することといたします。なお、本ポリシーで使用する用語の意味は、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に準拠するものとします。</p>
            <ol class="outline__list">
              <li>取得する個人情報の項目について
                <p>弊社は、お客様に関する次の個人情報を取得します。</p>
                <ol class="maru">
                  <li><span>&#9312;</span> 本人確認等に関する情報<br>氏名、生年月日、電子メールアドレスなど</li>
                  <li><span>&#9313;</span> その他の情報<br>お客様から弊社へのお問い合わせ・ご連絡などに関する情報など</li>
                </ol>
              </li>
              <li>利用目的について
                <p>弊社は、お客様の個人情報を、次に掲げる利用目的（以下「本利用目的」といいます。）の範囲内において、取得及び利用いたします。</p>
                <ol class="maru">
                  <li><span>&#9312;</span> 本サービスに関するご案内のため</li>
                  <li><span>&#9313;</span> 本サービスについてのお問い合わせに対する回答連絡のため</li>
                  <li><span>&#9314;</span> 本サービスについてのアフターサービスなど取引業務遂行のため</li>
                  <li><span>&#9315;</span> 本サービスの販売またはご提供を目的とするご本人様確認のため</li>
                  <li><span>&#9316;</span> 本サービスについてのアンケート調査及びモニター調査のため</li>
                  <li><span>&#9317;</span> 本サービスその他弊社の製品及びサービスについてのマーケティング活動のため</li>
                  <li><span>&#9318;</span> その他本サービスのご提供にあたって必要な場合</li>
                </ol>
              </li>
              <li>第三者提供について
                <p>弊社は、次に掲げる場合を除き、あらかじめお客様の同意を得ずに、弊社が取り扱う個人情報を第三者に提供いたしません。</p>
                <ol class="maru">
                  <li><span>&#9312;</span> 法令に基づく場合</li>
                  <li><span>&#9313;</span> 人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
                  <li><span>&#9314;</span> 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき。</li>
                  <li><span>&#9315;</span> 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。</li>
                  <li><span>&#9316;</span> その他法令で認められる場合</li>
                </ol>
              </li>
              <li>安全管理措置について
                <p>弊社は、その取り扱う個人情報への不正アクセス、個人情報の紛失、改ざん、漏洩などの防止その他個人情報の安全管理のために、必要かつ合理的な安全対策を講じます。弊社が講じる安全管理措置については、下記の「お問い合わせ窓口」までお問い合わせください。</p>
              </li>
              <li>ご本人様からの開示等の請求について
                <p>弊社は、個人情報に関して、ご本人様から保有個人データの利用目的の通知、保有個人データ若しくは第三者提供記録の開示、訂正、追加、削除又は保有個人データの利用の停止、消去又は第三者提供の停止等について請求（以下「開示等の請求」といいます。）を受けた場合、誠実に対応いたします。開示等の請求に応じる手続き及び開示等の請求に係る手数料の額等については、下記の「お問い合わせ窓口」までお問い合わせください。</p>
              </li>
              <li>個人情報管理規程について
                <p>弊社は、弊社役員及び従業員に対し、個人情報を保護する重要性と必要性の理解促進を図り、個人情報の適切な管理を図るため、個人情報管理規程を定め、当該規程を遵守いたします。</p>
              </li>
            </ol>
          </section>
        </div>
        <footer>
          <div class="center">記</div>
          <p class="outline__signature">
            <dl>
              <dt>お問い合わせ窓口：</dt>
              <dd>有限会社フウズラボ<br>
                東京都千代田区九段南4－3－5－502<br>
                Eメール：superalbum@whoselab.com<br>
                URL: <a href="https://www.whoseslab.com" target="_blank">https://www.whoseslab.com</a>
              </dd>
            </dl>
          </p>
        </footer>
      </div>
    </div>

  </div>
</div>
</template>

<script setup>
</script>

<style lang='scss' scoped>
.center {
  text-align: center;
}

ol.maru  {
  margin: 0;
  padding: 0
}
ol.maru li  {
  list-style: none;
  padding-left: 1.3em;
  text-indent: -1.3em;
}
</style>
